import listMap from '../../../assets/src/js/helpers/list-map'

const blocks = document.querySelectorAll(
    `.wp-block-blufish-agent-listings`
);

if (blocks.length > 0) {
    blocks.forEach((block) => {
        listMap();
    });
}
