export default () => {
    const searchForm = document.querySelector('.js-search-form');
    if (!searchForm) return;

    setupMobile();

    // prevent page reload on submit
    searchForm.addEventListener('submit', (e) => {
        e.preventDefault();
        return false;
    });

    // popups
    const popups = document.querySelectorAll('.js-home-search-popup');
    if (popups.length > 0) {
        popups.forEach(popup => {
            popup.addEventListener('click', e => {
                e.stopPropagation();
            });
        });
    }

    // close filter popups on outside click
    document.querySelector('body').addEventListener('click', () => {
        document.querySelector('.js-home-search-filter-drop.is-active')?.classList.remove('is-active');
    });

    const reset = document.querySelector('.js-filter-reset');
    if (reset) {
        reset.addEventListener('click', () => {
            resetForm(true);
        });
    }

    // open filter popups
    const filterTriggers = document.querySelectorAll('.js-home-search-filter-trigger');
    if (filterTriggers.length > 0) {
        filterTriggers.forEach(filter => {
            filter.addEventListener('click', e => {
                filter.parentNode.classList.toggle('is-active');

                filterTriggers.forEach(f => {
                    if (f !== filter) {
                        f.parentNode.classList.remove('is-active');
                    }
                });

                e.stopPropagation();
            });
        });
    }

    function setupMobile() {
        const mobileTrigger = document.querySelector('.js-mobile-filter-trigger');
        const mobileClose = document.querySelector('.js-mobile-filter-close');

        mobileTrigger.addEventListener('click', () => {
            searchForm.classList.add('is-active');
        });

        mobileClose.addEventListener('click', () => {
            searchForm.classList.remove('is-active');
        });
    }
}

export const resetForm = (submit = true) => {
    const searchForm = document.querySelector('.js-search-form');
    if (!searchForm) return;

    searchForm.reset();
    updateForm();

    if (submit) {
        submitSearch();
    }
}

export const updateForm = () => {
    updateStatusText()
    updatePriceText();
    updateBedsBathText();
    updateTypeText();
    updateMoreText();
}

const updateStatusText = () => {
    const el = document.querySelector('.js-search-filter-status-text');
    let text = el.getAttribute('data-text');
    const statuses = document.querySelectorAll('input[name="status[]"]:checked');

    if (statuses.length > 0) {
        text += ` (${statuses.length})`;
    }

    el.innerHTML = text;
}

export const formatPrice = (price) => {
    let label = 'K';
    let formatted = Math.round(price / 1000);

    if (formatted > 999) {
        formatted = Math.round(formatted / 10) / 100;
        label = 'M';
    }

    return `$${formatted}${label}`;
}

const updatePriceText = () => {
    const el = document.querySelector('.js-search-filter-price-text');
    let text = el.getAttribute('data-text');
    const min = document.getElementById('minprice').value;
    const max = document.getElementById('maxprice').value;

    if (min !== '') {
        if (max !== '') {
            text = `${formatPrice(min)} - ${formatPrice(max)}`;
        } else {
            text = `${formatPrice(min)}+`;
        }
    } else if (max !== '') {
        text = `Up to ${formatPrice(max)}`;
    }

    el.innerHTML = text;
}

const updateBedsBathText = () => {
    const el = document.querySelector('.js-search-filter-beds-baths-text');
    let text = el.getAttribute('data-text');
    const beds = document.querySelector('input[name="minbeds"]:checked')?.value;
    const baths = document.querySelector('input[name="minbaths"]:checked')?.value;

    if (beds) {
        if (baths) {
            text = `${beds}+ beds, ${baths}+ baths`;
        } else {
            text = `${beds}+ beds, any baths`;
        }
    } else if (baths) {
        text = `any beds, ${baths}+ baths`;
    }

    el.innerHTML = text;
}

const updateTypeText = () => {
    const el = document.querySelector('.js-search-filter-type-text');
    let text = el.getAttribute('data-text');
    const types = document.querySelectorAll('input[name="type[]"]:checked');

    if (types.length > 0) {
        text += ` (${types.length})`;
    }

    el.innerHTML = text;
}

const updateMoreText = () => {
    const el = document.querySelector('.js-search-filter-more-text');
    let text = el.getAttribute('data-text');
    const garage = document.querySelector('select[name="minGarageSpaces"]').value ? 1 : 0;
    const area = document.querySelector('select[name="minarea"]').value ? 1 : document.querySelector('select[name="maxarea"]').value ? 1 : 0;
    const lot = document.querySelector('select[name="minacres"]').value ? 1 : document.querySelector('select[name="maxacres"]').value ? 1 : 0;
    const year = document.querySelector('select[name="minyear"]').value ? 1 : document.querySelector('select[name="maxyear"]').value ? 1 : 0;
    const count = garage + area + lot + year;

    if (count > 0) {
        text += ` (${count})`;
    }

    el.innerHTML = text;
}