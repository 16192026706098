import Swiper from 'swiper';
import { Navigation, Keyboard } from 'swiper/modules';
import { populateFormFromUrlData } from '../includes/forms';
// mapbox is enqueued in wp

export default () => {
    modalSwipersInit();
    createHomeMap();
    updateContactFormAddress();
};

export const modalSwipersInit = () => {
    const images = document.querySelectorAll('#home-images img');
    if (!images.length) return;

    const slider = document.querySelector(`#home-images-slider .js-swiper`);
    if (!slider) return;

    const prev = document.querySelector(
        `#home-images-slider .swiper-button-prev`
    );
    const next = document.querySelector(
        `#home-images-slider .swiper-button-next`
    );

    const swiper = new Swiper(slider, {
        modules: [Navigation, Keyboard],
        a11y: true,
        rewind: true,
        grabCursor: true,
        keyboard: {
            enabled: true,
        },
        navigation: {
            enabled: true,
            prevEl: prev,
            nextEl: next,
        },
        slidesPerView: 1,
    });

    images.forEach((image) => {
        image.addEventListener('click', () => {
            const el = image.parentNode;
            const els = Array.prototype.slice.call(el.parentNode.children);

            swiper.slideTo(els.indexOf(el));
        });
    });
};

const updateContactFormAddress = () => {
    const form = document.querySelector(`.home-single-contact form`);
    if (!form) return;

    if (document.querySelector(`body.single-home`)) {
        const input = form.querySelector(`.populate_query_address input`);
        if (!input) return;

        const address = window.location.pathname
            .split('/')
            .filter(Boolean)
            .pop();
        input.setAttribute('value', address);
    } else {
        populateFormFromUrlData(form);
    }
};

const createHomeMap = () => {
    const homeMapEl = document.querySelector(`#home-map`);
    if (!homeMapEl) return;

    const lat = homeMapEl.getAttribute('data-lat');
    const lng = homeMapEl.getAttribute('data-lng');
    if (!lat && !lng) return;

    const coords = [lng, lat];

    const mapboxConfig = oceanFloor.options.third_party.mapbox;
    const homeMap = new mapboxgl.Map({
        accessToken: mapboxConfig.api_key,
        container: 'home-map',
        cooperativeGestures: true,
        center: coords,
        zoom: 17,
        maxZoom: 18,
        style: mapboxConfig.style,
    });

    homeMap.addControl(
        new mapboxgl.NavigationControl({
            showCompass: false,
        }),
        'bottom-right'
    );

    const markerEl = document.createElement('div');
    markerEl.className = `marker marker--home-map`;
    const marker = new mapboxgl.Marker({
        element: markerEl,
    }).setLngLat(coords);

    marker.addTo(homeMap);
};
