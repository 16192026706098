/**
 * Get coordinates from location latitude and longitude
 * @param {Array} locations array of locations
 * @returns array of lat/lng coordinates
 */
export const getLocationsCoordinates = (locations) => {
    let coords = [];

    locations.map((location) => {
        const address = getDataLatLng(location);
        const lat = parseFloat(address.lat);
        const lng = parseFloat(address.lng);

        coords.push([ lng, lat ]);
    });

    return coords;
};

/**
 * Find map bounds for all locations
 * @param {Array} coordinates array of locations coordinates
 * @returns
 */
export const getLocationsBounds = (coordinates) => {
    const bounds = coordinates.reduce(
        function (prev, curr) {
            if ( curr[1] < prev.min.lat ) prev.min.lat = curr[1];
            if ( curr[1] > prev.max.lat ) prev.max.lat = curr[1];
            if ( curr[0] < prev.min.lng ) prev.min.lng = curr[0];
            if ( curr[0] > prev.max.lng ) prev.max.lng = curr[0];
            return prev;
        },
        {
            min: { lat: Infinity, lng: Infinity },
            max: { lat: -Infinity, lng: -Infinity },
        }
    );

    return [ bounds.max, bounds.min ];
};

export const getDataLatLng = (location) => {
    let address;

    if ( location.lat && location.lng ) {
        address = location;
    } else if ( location.address_lat && location.address_lng ) {
        address = { lat: location.address_lat, lng: location.address_lng };
    } else if ( location.address ) {
        address = location.address;
    } else if ( location.info && location.info.address ) {
        address = location.info.address;
    } else if ( location.center ) {
        address = { lat: location.center.lat, lng: location.center.lng };
    } else {
        address = { lat: location[1], lng: location[0] };
    }

    return address;
};