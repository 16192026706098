import wpAjax from "./wpAjax";
import Swiper from 'swiper';
import {Navigation, Keyboard, Autoplay, Pagination, FreeMode, EffectFade} from 'swiper/modules';
import MicroModal from "micromodal";
import Accordions from "../includes/accordions";
import {getLocationsBounds, getLocationsCoordinates} from "./map";
import {serializeForm} from "../includes/forms";
import homeSingle from "./homeSingle";
import homeSearch, { updateForm, resetForm, formatPrice } from "./homeSearch";
// mapbox is enqueued in wp

export default () => {
    let map;
    let markers;
    const openTrigger = 'data-custom-open';
    const searchForm = document.querySelector('.js-search-form');
    const listingsWrapper = document.querySelector('.js-home-listings');
    const singleWrapper = document.querySelector('.js-single-render');

    if (!listingsWrapper && !singleWrapper) return;

    const searchList = document.querySelector('.js-home-search-list');
    const listCount = document.querySelector('.js-homes-list-count');

    const isFitBounds = () => {
        return listingsWrapper.classList.contains('is-fit-bounds');
    };

    const singleHome = document.querySelector('[data-single]');
    const homeModal = document.querySelector(`#home-single-modal`);

    MicroModal.init(); // NEEDED TO RE-INIT THE DEFAULT MICROMODAL TRIGGERS
    homeSingle();
    homeSearch();
    setOffsetHeight();

    const config = {
        openTrigger: openTrigger,
        disableScroll: true,
        disableFocus: false,
        awaitOpenAnimation: false,
        awaitCloseAnimation: false,
        onShow: modal => {
            modalOnShow(modal);
        },
        onClose: modal => {
            updateUrlAddress(null);
        },
    };

    const initConfig = {
        ...config,
        onShow: (modal) => {
            const mlsId = parseInt(modal.getAttribute('data-mls-id'));
            if (!mlsId) return;

            const home = window.homes.filter(h => h.mls_id === mlsId)[0];
            const data = {
                action: 'single_home',
                home: home,
            };

            modal.classList.add('is-loading');

            wpAjax(data, response => {
                const homeSingle = modal.querySelector('.home-single');
                const modalContent = modal.querySelector('.js-single-render');

                document.getElementById('home-images')?.remove();
                document.getElementById('home-images-slider')?.remove();

                modalContent.innerHTML = response.data.html;
                homeSingle.classList.toggle('is-alternate', response.data.template === 'alternate');

                const modals = modal.querySelectorAll('.modal');
                const siteContent = document.querySelector('.js-site-content');

                modals.forEach(modal => {
                    siteContent.appendChild(modal);
                });

                modalOnShow(modal);
                Accordions();

                modal.classList.remove('is-loading');
            });
        }
    }

    if (singleHome) {
        homeModal.setAttribute('data-mls-id', singleHome.getAttribute('data-mls-id'));
        homeModal.setAttribute('data-micromodal-url', singleHome.getAttribute('data-url'));
        MicroModal.show('home-single-modal', config);
    }

    function modalOnShow(modal) {
        MicroModal.init(); // NEEDED TO RE-INIT THE DEFAULT MICROMODAL TRIGGERS

        const address = modal.getAttribute('data-micromodal-url') ?? null;

        updateUrlAddress(address);

        homeSingle();

        modal.querySelector('.modal__container').scrollTo({ top: 0, behavior: 'smooth' });

        const handlePopstate = () => {
            if (modal.classList.contains('is-open')) {
                MicroModal.close(modal.getAttribute('id'));
                updateUrlAddress();

                window.removeEventListener('popstate', handlePopstate);
            }
        }
        window.addEventListener('popstate', handlePopstate);

        // setup jump links for nav and agent card link
        const modalJumpLinks = modal.querySelectorAll(`a[href^="#"]`);
        if (modalJumpLinks.length > 0) {
            modalJumpLinks.forEach(link => {
                const id = link.getAttribute('href');
                const el = modal.querySelector(id);
                if (!el) return;

                link.addEventListener('click', (e) => {
                    e.preventDefault();
                    el.scrollIntoView({
                        behavior: 'smooth',
                    });
                });
            })
        }
    }

    const mapViewControl = document.querySelector('.js-control-map');
    const listViewControl = document.querySelector('.js-control-list');
    if (mapViewControl && listViewControl) {
        mapViewControl.addEventListener('click', () => {
            listingsWrapper.classList.remove('is-list-view');
            listingsWrapper.classList.add('is-map-view');
            refreshMap();
        });

        listViewControl.addEventListener('click', () => {
            listingsWrapper.classList.remove('is-map-view');
            listingsWrapper.classList.add('is-list-view');
            refreshMap();
        });
    }


    const mapboxConfig = oceanFloor.options.third_party.mapbox;
    map = new mapboxgl.Map({
        accessToken: mapboxConfig.api_key,
        container: 'map',
        cooperativeGestures: true,
        center: [-105.0199176, 39.7642224],
        zoom: 12,
        style: mapboxConfig.style,
    });
    markers = [];
    const nav = new mapboxgl.NavigationControl({
        showCompass: false,
    });
    map.addControl(nav, 'bottom-right');

    map.on('load', () => {
        submitSearch();
    });

    map.on('dragend', () => {
        if (!isFitBounds()) {
            submitSearch(map);
        }
    });

    map.on('zoomend', () => {
        if (!isFitBounds()) {
            submitSearch(map);
        }
    });

    function getPoints() {
        if (map) {
            const bounds = map.getBounds();
            const sw = `${bounds._sw.lat},${bounds._sw.lng}`;
            const nw = `${bounds._ne.lat},${bounds._sw.lng}`;
            const ne = `${bounds._ne.lat},${bounds._ne.lng}`;
            const se = `${bounds._sw.lat},${bounds._ne.lng}`;

            return {
                sw: sw,
                nw: nw,
                ne: ne,
                se: se,
            };
        }
    }

    function clearMarkers() {
        if (markers) {
            for (let i = markers.length - 1; i >= 0; i--) {
                markers[i].remove();
            }
            markers = [];
        }
    }

    function addHomes(homes) {
        homes.forEach(home => {
            const markerEl = document.createElement('div');
            const price = formatPrice(home.price);
            markerEl.className = `marker`;
            markerEl.innerHTML = `<span>${price}</span>`;

            const popupHtml = document.createElement('div');

            popupHtml.innerHTML = document.querySelector('.js-card-template').innerHTML;

            const card = popupHtml.querySelector('.js-home-card');

            card.setAttribute('data-mls-id', home.mls_id);
            card.setAttribute('data-url', home.address_url);
            if (home.images.length > 0) card.querySelector('.js-home-card-image').innerHTML = `<img src="${home.images[0]}" alt="">`;
            card.querySelector('.js-home-card-price').innerHTML = home?.price ? `$${home.price.toLocaleString(
                undefined, {minimumFractionDigits: 0}
            )}` : '';
            card.querySelector('.js-home-card-address').innerHTML = `${home.address_street} <br>${home.address_city}, ${home.address_state} ${home.address_zip}`;
            card.querySelector('.js-home-card-sqft').innerHTML = home.sqft;
            card.querySelector('.js-home-card-beds').innerHTML = home.beds;
            card.querySelector('.js-home-card-garage').innerHTML = home.cars;
            card.querySelector('.js-home-card-baths').innerHTML = home.baths_total;

            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: true,
                closeOnMove: false,
                focusAfterOpen: false,
                offset: 10,
                className: 'marker-popup',
                maxWidth: '300px',
            })
                .setLngLat([home.address_lng, home.address_lat])
                .setHTML(popupHtml.outerHTML)
                .addTo(map);

            const marker = new mapboxgl.Marker({
                element: markerEl,
            }).setLngLat([home.address_lng, home.address_lat]);

            marker.setPopup(popup)

            markers.push(marker);

            marker.addTo(map);

            popup.on('open', () => {
                const card = popup.getElement().querySelector('.js-home-card');
                card.addEventListener('click', e => {
                    homeModal.setAttribute('data-mls-id', card.getAttribute('data-mls-id'));
                    homeModal.setAttribute('data-micromodal-url', card.getAttribute('data-url'));
                    MicroModal.show('home-single-modal', initConfig);

                    e.stopPropagation();
                });
            });
        });
    }

    function submitSearch() {
        updateForm();

        const data = serializeForm(searchForm);
        data.action = 'search_homes';

        const isMapView = listingsWrapper.classList.contains('is-map-view');

        if (!isFitBounds() && isMapView) {
            data.points = getPoints(map);
        }

        listingsWrapper.classList.add('is-loading');


        wpAjax(data, response => {
            clearMarkers();

            if (map) {
                addHomes(response.data.homes);
            }

            window.homes = response.data.homes;

            let count = response.data.homes.length;

            if (count >= 500) {
                count = '500+';
            }

            if (count === 1) {
                listingsWrapper.classList.add('is-narrow-list');
            } else {
                listingsWrapper.classList.remove('is-narrow-list');
            }

            count += ' Results';

            listCount.innerHTML = count;
            searchList.innerHTML = response.data.html;


            homesListInit(searchList);
            MicroModal.init(initConfig);

            refreshMap();

            listingsWrapper.classList.remove('is-loading');
        });
    }


    if (searchForm) {
        const formElements = searchForm.querySelectorAll('input, select');

        if (formElements.length > 0) {
            formElements.forEach(el => {
                el.addEventListener('change', () => {
                    submitSearch();
                });
            });
        }

        const chrTitles = document.querySelectorAll('.wp-block-blufish-chr-listings .js-tabs-title');
        if (chrTitles) {
            chrTitles.forEach(title => {
                title.addEventListener('click', () => {
                    const slug = title.getAttribute('data-slug');
                    const status = document.querySelectorAll('[name="status[]"]');
                    const statusGroup = document.querySelector('.js-filter-status-group');
                    const showBrokers = document.querySelector('[name="show_brokers"]');

                    chrTitles.forEach(t => {
                        t.classList.remove('is-active');
                    });

                    title.classList.add('is-active');

                    if (slug === 'active') {
                        status.forEach(item => {
                            item.checked = item.value === 'Active';
                        });
                        statusGroup.classList.remove('is-disabled');
                        listingsWrapper.classList.remove('is-map-view--full');
                        showBrokers.value = '1';
                        listingsWrapper.classList.add('is-brokers-view');
                        listingsWrapper.classList.add('is-fit-bounds');
                        map.resize();
                    } else if (slug === 'sold') {
                        status.forEach(item => {
                            item.checked = item.value === 'Closed';
                        });
                        statusGroup.classList.add('is-disabled');
                        listingsWrapper.classList.add('is-map-view');
                        listingsWrapper.classList.add('is-map-view--full');
                        listingsWrapper.classList.remove('is-list-view');
                        showBrokers.value = '1';
                        listingsWrapper.classList.add('is-brokers-view');
                        listingsWrapper.classList.add('is-fit-bounds');
                        map.resize();
                    } else {
                        status.forEach(item => {
                            item.checked = item.value === 'Active' ||
                                item.value === 'ActiveUnderContract' ||
                                item.value === 'Pending' ||
                                item.value === 'ComingSoon';
                        });
                        statusGroup.classList.remove('is-disabled');
                        listingsWrapper.classList.remove('is-map-view--full');
                        showBrokers.value = '0';
                        listingsWrapper.classList.remove('is-brokers-view');
                        listingsWrapper.classList.remove('is-fit-bounds');
                    }

                    submitSearch();
                    ScrollTrigger.refresh();
                });
            });
        }

        const agentTitles = document.querySelectorAll('.wp-block-blufish-agent-listings .js-tabs-title');
        if (agentTitles) {
            agentTitles.forEach(title => {
                title.addEventListener('click', () => {
                    const slug = title.getAttribute('data-slug');
                    const showBrokers = document.querySelector('[name="show_brokers"]');
                    const showAgent = document.querySelector('[name="show_agent"]');

                    agentTitles.forEach(t => {
                        t.classList.remove('is-active');
                    });

                    title.classList.add('is-active');

                    if (slug === 'agent-listings') {
                        showAgent.value = '1';
                        showBrokers.value = '0';
                        listingsWrapper.classList.add('is-fit-bounds');
                        listingsWrapper.classList.add('is-agent-view');
                        listingsWrapper.classList.remove('is-brokers-view');
                        resetForm(false);
                    } else if (slug === 'all-listings') {
                        showAgent.value = '0';
                        showBrokers.value = '1';
                        listingsWrapper.classList.add('is-fit-bounds');
                        listingsWrapper.classList.remove('is-agent-view');
                        listingsWrapper.classList.add('is-brokers-view');
                    } else {
                        showAgent.value = '0';
                        showBrokers.value = '0';
                        listingsWrapper.classList.remove('is-fit-bounds');
                        listingsWrapper.classList.remove('is-agent-view');
                        listingsWrapper.classList.remove('is-brokers-view');
                    }

                    submitSearch();
                    ScrollTrigger.refresh();
                });
            });
        }
    }



    function refreshMap() {
        map.resize();

        if (isFitBounds()) {
            fitMapBoundsToHomes();
        }
    }

    function fitMapBoundsToHomes() {
        if (homes.length === 0) {
            return;
        }

        const coords = getLocationsCoordinates(homes);
        const bounds = getLocationsBounds(coords);
        map.fitBounds(bounds, {
            padding: 64,
            maxZoom: 18,
        });
    }

    function homesListInit(searchList) {
        const modalTriggers = document.querySelectorAll(`[${openTrigger}]`);
        const slider = searchList.querySelector(`.js-swiper`);
        const pagination = searchList.querySelector(`.swiper-pagination`);
        const prev = searchList.querySelector(`.swiper-button-prev`);
        const next = searchList.querySelector(`.swiper-button-next`);

        new Swiper(slider, {
            modules: [Autoplay, Pagination, Navigation, FreeMode, Keyboard, EffectFade],
            a11y: true,
            effect: 'fade',
            autoHeight: true,
            rewind: false,
            grabCursor: false,
            allowTouchMove: false,
            resizeObserver: true,
            keyboard: {
                enabled: false,
            },
            pagination: {
                el: pagination,
                type: 'bullets',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                }
            },
            navigation: {
                enabled: true,
                prevEl: prev,
                nextEl: next,
            },
        });

        modalTriggers.forEach(trigger => {
            trigger.addEventListener('click', () => {
                homeModal.setAttribute('data-mls-id', trigger.getAttribute('data-mls-id'));
                homeModal.setAttribute('data-micromodal-url', trigger.getAttribute('data-url'));
            });
        });
    }
}

const updateUrlAddress = (address = null) => {
    let url = new URL(window.location.href);

    if (address) {
        url.searchParams.set('address', address)
    } else {
        url.searchParams.delete('address')
    }

    history.replaceState({}, '', url.href);
}

const setOffsetHeight = () => {
    let offset = 0;
    const body = document.querySelector(`body`);
    const filters = document.querySelector(`.js-search-form`);
    if (filters) offset += filters.offsetHeight;

    body.style.setProperty(`--offset-height`, `${offset}px`);
};
